<template>
  <b-card-group columns>
    <ImageView :item="item" v-for="(item, inx) in items" :key="inx"/>
  </b-card-group>
</template>

<script>
import { BCardGroup } from 'bootstrap-vue';

export default {
  components: {
    BCardGroup,
    ImageView: () => import('@/components/atoms/ImageView'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    page: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    // console.log(this.items);
  },
};
</script>
<style>
@media (min-width: 576px){
  .card-columns {
    -moz-column-count: 4 !important;
    column-count: 4 !important;
  }
}
</style>
